import React from 'react'
import Layout from '../components/layout'
import PageTitle from '../components/pageTitle'
import Seo from '../components/seo'

export default function Contact() {
  return (
    <Layout>
      <Seo title="Terms And Conditions" />
      <PageTitle
        title="Terms And Conditions"
      />
      <div className="py-8 px-8 w-full">
        <span className='text-sm md:text-lg'><strong>ATTENTION: </strong>PLEASE READ ALL THESE PROVISIONS (COLLECTIVELY THE “TERMS”) CAREFULLY BEFORE USING THIS WEBSITE (“Site”). USING THIS WEBSITE INDICATES YOUR AGREEMENT AND ACCEPTANCE OF THESE TERMS. IF YOU DO NOT ACCEPT THESE TERMS, DO NOT USE THIS WEBSITE OR DOWNLOAD MATERIALS FROM THIS SITE.</span>
        <h2 className='py-4 text-lg md:text-xl 2xl:text-2xl '>1. The Site And It's Use</h2>
        <p className="py-4">This website is operated by Gazelle® Industrial Co. and offers general information about Gazelle, its products and services and other information of general interest. Access to and use of this Site are subject to the terms and conditions set forth in this notice, and all applicable laws and regulations (including laws and regulations governing copyrights and trademarks). You agree to be bound by these Terms when you use, browse or access any part of the Site. </p>
        <p>Access to the Site is permitted on a temporary basis, and we reserve the right to withdraw or amend the service we provide on the Site without notice. It is your responsibility to ensure your computer or mobile device meets all the necessary technical specifications to enable you to access and use the Site. We may, from time to time, restrict access to certain features, content, or all or part of the Site. We cannot guarantee the continuous, uninterrupted or error-free operability of the Site.  There may be times when all of, or certain features, parts or content of, the Site, become unavailable (whether on a scheduled or unscheduled basis) or are modified, suspended or withdrawn by us, in our sole discretion, without notice to you.  You agree that we will not be liable to you or to any third party for any unavailability, modification, suspension or withdrawal of any Site, or any features, parts or content of the Site.</p>
      </div>
    </Layout>
  )
}
